export const nps = {
    name: "nps",
    title: "NPS Question",
    questionJSON: {
      type: "rating",
      rateCount: 11,
      rateMin: 0,
      rateMax: 10,
      minRateDescription: "Highly Unlikely",
      maxRateDescription: "Highly Likely",
      cssClass: "custom-nps-question",
      placeholder: "Select a country...",
      choicesByUrl: {
        url: "https://surveyjs.io/api/CountriesExample",
      },
    },
  };
  
  export const csat = {
    name: "csat",
    title: "CSAT Question",
    questionJSON: {
      type: "rating",
      rateType: "smileys",
      rateCount: 5,
      cssClass: "custom-nps-question",
      rateMax: 5,
      choicesByUrl: {
        url: "https://surveyjs.io/api/CountriesExample",
      },
    },
  };
  